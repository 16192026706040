import { prop } from 'eshop-defaults/lib';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { stripHtmlTags } from '.';
import { getImagePath } from './product';
import { __ } from 'react-i18n';

export function isEmptyObj(obj) {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return true;
}

export function getCmsMetaTags(content: any) {
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }
  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }
  if (
    !metaTags ||
    !metaTags.og_description ||
    Object.keys(metaTags.og_description).length === 0
  ) {
    metaTags.og_description = content && content.annotation;
    metaTags.description = content && content.annotation;
  }

  return metaTags;
}

export function getEbookMetaTags(ebook: any) {
  const content = prop(ebook, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getAuthorMetaTags(author: any) {
  const content = prop(author, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getProductMetaTags(product, lang = 'sk') {
  const productPublish = prop(product, 'publish', []).find(
    p => p.lang_id === lang,
  );
  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    metaTags = prop(productPublish, 'content.json_content.meta');

    if (!metaTags.title || !metaTags.title.length) {
      metaTags.title = prop(productPublish, 'product_name') || product.name;
    }

    if (!metaTags.description) {
      metaTags.description = prop(
        productPublish,
        'content.json_content.short_description',
      )
        ? prop(productPublish, 'content.json_content.short_description')
        : stripHtmlTags(
            prop(productPublish, 'content.json_content.annotation'),
          );
    }
  }

  if (metaTags.description) {
    metaTags.description = stripHtmlTags(metaTags.description)
      .trim()
      .slice(0, 160);
  }

  if (!metaTags.og_title) {
    metaTags.og_title =
      prop(product, 'publish.0.content.json_content.meta.og_title', '') ||
      prop(productPublish, 'product_name') ||
      prop(product, 'name', '');
  }

  if (!metaTags.og_type) {
    metaTags.og_type =
      prop(product, 'publish.0.content.json_content.meta.og_type', '') ||
      'website';
  }

  if (!metaTags.og_description) {
    metaTags.og_description =
      prop(product, 'publish.0.content.json_content.meta.og_description', '') ||
      stripHtmlTags(
        prop(product, 'publish.0.content.json_content.short_description', ''),
      );
  }

  if (!metaTags.og_url) {
    metaTags.og_url =
      prop(product, 'publish.0.content.json_content.meta.og_url', '') ||
      `${process.env.REACT_APP_BASE_URL}/${prop(product, 'url')}`;
  }

  if (!metaTags.og_image) {
    metaTags.og_image =
      prop(product, 'publish.0.content.json_content.meta.og_image', '') ||
      getImagePath(prop(product, 'picture'), { width: 1005, height: 1005 });
  }

  if (!metaTags.og_image_width) {
    metaTags.og_image_width = 1005;
  }
  if (!metaTags.og_image_height) {
    metaTags.og_image_height = 1005;
  }
  return metaTags;
}

export function resolveMetaTagsForSpecialCategory(name: string) {
  switch (name) {
    case 'Výpredaj':
      return {
        title: __(
          'Výpredaj - Zľavy na vykurovaciu techniku, náradie a spojovací materiál',
        ),
        description: __(`Využite výpredaj na ZEMPLÍN, s.r.o.! Získajte kvalitnú vykurovaciu techniku, náradie,
spojovací materiál a ďalšie produkty so zľavami. Nakupujte výhodne, kým je to možné!`),
        keywords: __(`výpredaj, zľavy, akcie, vykurovacia technika, náradie, spojovací materiál, ZEMPLÍN,
s.r.o., výhodné nákupy, výpredajové ponuky, zľavy na produkty`),
      };
    case 'Novinky':
      return {
        title: __('Novinky - Aktuálne produkty'),
        description: __(`Objavte novinky na ZEMPLÍN, s.r.o. – najnovšie produkty a ponuky v oblasti
spojovacieho materiálu, vykurovacej techniky a náradia. Buďte informovaní o našich
najnovších produktoch a ponukách!`),
        keywords: __(`novinky, akcie, nové produkty, ZEMPLÍN, s.r.o., novinky v vykurovacej technike, nové
náradie, nové spojovacie materiály, aktuálne ponuky, novinky v e-shope`),
      };
    case 'Akcie':
      return {
        title: __('Akcie - Výhodné ponuky a zľavy na produkty'),
        description: __(`Využite výhodné akcie a zľavy na spojovací materiál, vykurovaciu techniku, náradie a
ďalšie produkty na ZEMPLÍN, s.r.o. Sledujte naše špeciálne ponuky a ušetrite!`),
        keywords: __(`akcie, zľavy, výhodné ponuky, špeciálne akcie, ZEMPLÍN, s.r.o., vykurovacia technika,
náradie, spojovací materiál, akčné ponuky, zľavy na produkty`),
      };

    default:
      return {};
  }
}

export function getCategoryMetaTags(category) {
  if (!category) {
    return null;
  }
  const metaTags: any =
    prop(category, 'publish.0.content.json_content.meta') ||
    prop(category, 'content.0.json_content.meta', {});

  // Create copy of readOnly object
  const remappedMetaTags = { ...metaTags };

  if (!remappedMetaTags.title) {
    remappedMetaTags.title =
      prop(category, 'publish.0.content.json_content.meta.title') ||
      prop(category, 'publish.0.category_name') ||
      prop(category, 'content.0.name', prop(category, 'category_name', '')) ||
      prop(category, 'category_name', '');
  }

  if (!remappedMetaTags.description) {
    remappedMetaTags.description =
      prop(category, 'publish.0.content.json_content.meta.description') ||
      prop(category, 'publish.0.category_description') ||
      prop(category, 'content.0.json_content.meta.description', '') ||
      stripHtmlTags(prop(category, 'content.0.json_content.body', ''));
  }

  if (!remappedMetaTags.keywords) {
    remappedMetaTags.keywords =
      prop(category, 'publish.0.content.json_content.meta.keywords') ||
      prop(category, 'content.0.json_content.meta.keywords', '');
  }

  if (!remappedMetaTags.og_title) {
    remappedMetaTags.og_title =
      prop(category, 'publish.0.content.json_content.meta.og_title') ||
      prop(category, 'publish.0.category_name') ||
      prop(category, 'content.0.json_content.meta.og_title', '') ||
      prop(category, 'category_name', '');
  }

  if (!remappedMetaTags.og_type) {
    remappedMetaTags.og_type =
      prop(category, 'publish.0.content.json_content.meta.og_type ') ||
      prop(category, 'content.0.json_content.meta.og_type', '') ||
      'website';
  }

  if (!remappedMetaTags.og_type) {
    remappedMetaTags.og_description =
      prop(category, 'publish.0.content.json_content.meta.og_description') ||
      prop(category, 'publish.0.category_description') ||
      prop(category, 'content.0.json_content.meta.og_description', '') ||
      stripHtmlTags(prop(category, 'content.0.json_content.body', ''));
  }

  if (!remappedMetaTags.og_url) {
    remappedMetaTags.og_url =
      prop(category, 'publish.0.content.json_content.meta.og_url') ||
      prop(category, 'content.0.json_content.meta.og_url', '') ||
      `${process.env.REACT_APP_BASE_URL}${resolveCategoryUrl(
        prop(category, 'category_id'),
        prop(category, 'url'),
      )}`;
  }

  if (!remappedMetaTags.og_url) {
    remappedMetaTags.og_image =
      prop(category, 'publish.0.content.json_content.meta.og_image') ||
      prop(category, 'content.0.json_content.meta.og_image', '') ||
      getImagePath(prop(category, 'image'), { width: 1005, height: 1005 });
  }

  if (!remappedMetaTags.og_image_width) {
    remappedMetaTags.og_image_width = 1005;
  }

  if (!remappedMetaTags.og_image_height) {
    remappedMetaTags.og_image_height = 1005;
  }

  return remappedMetaTags;
}

export function getFooterArticleMetaTags(article) {
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (article && (!metaTags.title || isEmptyObj(metaTags.title))) {
    metaTags.title = article.name;
  }

  return metaTags;
}

export const resetGtm = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(function(this: any) {
      this.reset();
    });
  }
};
