/* eslint-disable */
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinLoaderWrapper,
  FlexRow,
  CategoryHeader,
  FlexRowCenterVertical,
  ViewSwitcher,
  ProductList,
  ProductTableList,
  Pagination,
  ZemplinCategoryFilter as CategoryFilter,
  ZemplinFilterType as FilterType,
  ZemplinResetFilter as ResetFilter,
  ZemplinFilterBackButton as FilterBackButton,
  ZemplinMobileCategoryFilterButtons as MobileCategoryFilterButtons,
  MobileNavMenu,
  ZemplinMobileFilterMenu as MobileFilterMenu,
  ZemplinLinksFilter as LinksFilter,
  ZemplinActiveFilters as ActiveFilters,
  ZemplinCategoryTopFilters as CategoryTopFilters,
  ZemplinCategoryFilterCheckboxes as CategoryFilterCheckboxes,
} from 'eshop-defaults';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { useLocalStorage } from '../../utilities/hooks';
import {
  getCategoryMetaTags,
  resolveMetaTagsForSpecialCategory,
} from '../../utilities/metatags';
import {
  addItemToCart,
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import { isCategory } from 'eshop-defaults/lib/components/Zemplin/selectors';
import { SortWrapper } from './Category';
import { trackSelectProduct } from '../../utilities/gtm';
import TrackViewItemList from '../Analytics/TrackViewItemList';

export interface Props {
  query: string;
  dispatch: any;
  isFetching: boolean;
  products: ThenArg<typeof API.searchProducts>;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  pathname: string;
  cart: ThenArg<typeof API.getCart> | null;
}

function SpecialCategory({
  isFetching,
  products,
  data,
  query,
  dispatch,
  user,
  catName,
  pathname,
  cart,
}: Props) {
  const [currentView, setCurrentView] = useLocalStorage('product-view', 'card');
  const categoryName = prop(data, 'category_name', catName);
  const categoryDesc = prop(data, 'category_descr', '') || '';

  React.useEffect(() => {
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CUSTOM, {
        name: catName,
        url: pathname,
      }),
    );
  }, [data, dispatch]);

  const cartItems = prop(cart, 'items', []);
  const cartItemsIds: any = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  const renderRightSide = () => {
    const offset = prop(products, 'offset', 0);
    const limit = prop(products, 'limit', 0);
    const total = prop(products, 'total', 0);

    const isCardView = currentView === 'card';

    return (
      <RightSideWrapper>
        <CategoryHeader title={categoryName} desc={categoryDesc} />
        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !products || !products.products ? null : (
          <>
            <SortWrapper>
              <ViewSwitcher changeView={changeView} currentView={currentView} />
            </SortWrapper>
            <InfoTextWrapper>
              <InfoTextSpan>
                {__('Používaná merná jednotka Mks = 1000 ks')}
              </InfoTextSpan>
            </InfoTextWrapper>
            {products && products.products && products.products.length > 0 && (
              <TrackViewItemList
                products={products.products}
                // itemListName={`${__('Kategória')} ${categoryName}`}
                itemListName={`${catName}`}
              />
            )}
            {renderProductsCardView(isCardView)}
            {renderProductsListView(isCardView)}
            <Pagination
              query={query}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightSideWrapper>
    );
  };

  const renderProductsCardView = (isCardView: boolean) => {
    return (
      <ProductList
        isFetching={isFetching}
        products={products.products}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={isCardView}
        user={user}
        handleOrderNrChange={handleOrderNrChange}
        showPackage={true}
        cartItemsIds={cartItemsIds}
        trackSelectProduct={(p, idx) =>
          trackSelectProduct(p, `${catName}`, idx)
        }
      />
    );
  };

  const renderProductsListView = (isCardView: boolean) => {
    return (
      <ProductTableList
        isFetching={isFetching}
        products={products.products}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={!isCardView}
        user={user}
        handleOrderNrChange={handleOrderNrChange}
        showPackage={true}
        cartItemsIds={cartItemsIds}
        trackSelectProduct={(p, idx) =>
          trackSelectProduct(p, `${catName}`, idx)
        }
      />
    );
  };

  const changeView = () => {
    setCurrentView(currentView === 'card' ? 'list' : 'card');
  };

  const addToCart = (product: any) => {
    dispatch(
      setAddToCartModalVisibility(false, {
        ...product,
        item_list_name: catName,
      }),
    );
  };

  const addToRequest = (product: any) => {
    dispatch(
      setAddToCartModalVisibility(true, {
        ...product,
        item_list_name: catName,
      }),
    );
  };

  const handleOrderNrChange = (goodId, value) => {
    dispatch(setCustomerGoodOrderNr(goodId, value));
  };

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags tags={resolveMetaTagsForSpecialCategory(catName)} />
        {renderRightSide()}
      </Wrapper>

      <InfoBanner />
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding-top: ${rem(56)};
  margin-bottom: ${rem(80)} !important;

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   padding-top: ${rem(32)};
  //   margin-bottom: ${rem(32)} !important;
  //   max-width: ${rem(960)} !important;
  // }

  ${({ theme }) => theme.mediab.l1050`
      padding-top: ${rem(32)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

const RightSideWrapper = styled(FlexCol)`
  /* padding: ${rem(24)}; */
  width: 100%;
`;

// const SortWrapper = styled(FlexRowCenterVertical)`
//   margin-bottom: ${rem(8)};
//   justify-content: space-between;

//   ${({ theme }) => theme.mediab.l1050`
//       margin-bottom: ${rem(24)};
//   `}
// `;

const InfoTextWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(32)};
`;

const InfoTextSpan = styled.span`
  font-size: ${rem(14)};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export default SpecialCategory;
