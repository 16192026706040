import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { __r } from 'react-i18n';
import API from './services/API';
import { cartRoutes } from './utilities/cart';

// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';
import UrlMapper from './containers/UrlMapper/UrlMapper';
import SearchResultsNew from './containers/Search/SearchResultsNew';
import ProductContainer from './containers/Product/Product';
import LoginPage from './containers/Auth/LoginPage';
import RegisterPage from './containers/Auth/RegisterPage';
import PreRegisterPage from './containers/Auth/PreRegisterPage';
import B2BRegisterPage from './containers/Auth/B2BRegisterPage';
import CMSArticles from './containers/CMS/CMSArticles';
import ForgotPassword from './containers/Auth/ForgotPassword';
import MyAccount from './containers/MyAccount/MyAccount';
import MyOrders from './containers/MyAccount/MyOrders';
import MyRequests from './containers/MyAccount/MyRequests';
import MyRequestsDetail from './containers/MyAccount/MyRequestsDetail';
import MyOrdersDetail from './containers/MyAccount/MyOrdersDetail';
import MyInfoContainer from './containers/MyAccount/MyInfo';
import MyDeliveryAddressesContainer from './containers/MyAccount/MyDeliveryAddresses';
import Activation from './containers/MyAccount/Activation';
import NewPassword from './containers/MyAccount/NewPassword';
import ChangePassword from './containers/MyAccount/ChangePassword';
import Category from './containers/Category/Category';
import AccountManagementContainer from './containers/MyAccount/AccountManagement';
import Cart from './containers/Cart/Cart';
import CartFirstStep from './containers/Cart/CartFirstStep';
import CartSecondStep from './containers/Cart/CartSecondStep';
import CartThirdStep from './containers/Cart/CartThirdStep';
import FinishedOrder from './containers/Cart/FinishedOrder';
import Contact from './containers/Contact/Contact';
import ContactPersonsPage from './containers/Contact/ContactPersonsPage';
import ContactInfoPage from './containers/Contact/ContactInfoPage';
import ImportCart from './containers/ImportCart/ImportCart';
import MyInvoices from './containers/MyAccount/MyInvoices';
import MyBackorder from './containers/MyAccount/MyBackorder';
import Scan from './containers/Scan/Scan';
import { resetGtm } from './utilities/metatags';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  setTimeout(() => {
    const pathname = window.location.pathname;
    const search = window.location.search;
    if (typeof window !== 'undefined' && window.dataLayer) {
      resetGtm();
      window.dataLayer.push({
        event: 'pageview',
        page: `${pathname}`,
      });
    }
  }, 2000);
};

const renderActivation = props => {
  return <Activation API={API} params={props.params} />;
};

const Routes = (props: any) => {
  return (
    <Router onUpdate={scrollToTop} history={props.history}>
      <Route path="/" component={App}>
        <Route
          path={__r('routes:vyhladavanie', '/vyhladavanie')}
          component={SearchResultsNew}
        />
        {[
          __r('routes:novinky', '/novinky'),
          __r('routes:akcie', '/akcie'),
          __r('routes:vypredaj', '/vypredaj'),
          `${__r(
            'routes:kategoria',
            '/kategoria',
          )}/:category_id/:category_url(/**)`,
        ].map((path, index) => (
          <Route path={path} component={Category} key={index} />
        ))}

        <Route
          path={__r('routes:prihlasenie', '/prihlasenie')}
          component={LoginPage}
        />
        <Route
          path={__r('routes:registracia', '/registracia')}
          component={PreRegisterPage}
        />
        <Route
          path={__r('routes:registracia-b2c', '/registracia-b2c')}
          component={RegisterPage}
        />
        <Route
          path={__r('routes:registracia-b2b', '/registracia-b2b')}
          component={B2BRegisterPage}
        />
        <Route
          path={__r('routes:zabudnute-heslo', '/zabudnute-heslo')}
          component={ForgotPassword}
        />
        <Route path={__r('routes:moj-ucet', '/moj-ucet')} component={MyAccount}>
          <Route
            path={__r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky')}
            component={MyOrders}
          />
          <Route
            path={__r('routes:moj-ucet/faktury', '/moj-ucet/faktury')}
            component={MyInvoices}
          />
          <Route
            path={`${__r(
              'routes:moj-ucet/objednavky',
              '/moj-ucet/objednavky',
            )}/:id`}
            component={MyOrdersDetail}
          />
          <Route
            path={__r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty')}
            component={MyRequests}
          />
          <Route
            path={`${__r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty')}/:id`}
            component={MyRequestsDetail}
          />
          <Route
            path={__r(
              'routes:moj-ucet/nedodany-tovar',
              '/moj-ucet/nedodany-tovar',
            )}
            component={MyBackorder}
          />
          <Route
            path={__r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje')}
            component={MyInfoContainer}
          />
          <Route
            path={__r(
              'routes:moj-ucet/moje-dodacie-adresy',
              '/moj-ucet/moje-dodacie-adresy',
            )}
            component={MyDeliveryAddressesContainer}
          />
          <Route
            path={__r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov')}
            component={AccountManagementContainer}
          />
          <Route
            path={__r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla')}
            component={ChangePassword}
          />
          <IndexRoute component={MyOrders} />
        </Route>

        <Route path={__r(cartRoutes.INDEX, '/kosik')} component={Cart}>
          <Route
            path={__r(cartRoutes.SECOND_STEP, '/kosik/osobne-udaje')}
            component={CartSecondStep}
          />
          <Route
            path={__r(cartRoutes.THIRD_STEP, '/kosik/dodanie-a-platba')}
            component={CartThirdStep}
          />
          <IndexRoute component={CartFirstStep} />
        </Route>
        <Route
          path={`${__r(
            'routes:dokoncena-objednavka',
            '/dokoncena-objednavka',
          )}/:orderPublicId`}
          component={FinishedOrder}
        />

        <Route
          path={__r('routes:aktuality', '/aktuality')}
          component={CMSArticles}
        />

        <Route
          path={__r('routes:tipy-a-rady', '/tipy-a-rady')}
          component={CMSArticles}
        />

        <Route path={__r('routes:sluzby', '/sluzby')} component={CMSArticles} />

        <Route
          path={__r('routes:import-objednavky', '/import-objednavky')}
          component={ImportCart}
        />

        <Route
          path={`${__r('routes:nove-heslo', '/nove-heslo')}/:token`}
          component={NewPassword}
        />

        <Route path={__r('routes:kontakt', '/kontakt')} component={Contact}>
          <Route
            path={__r(
              'routes:kontakt/kontaktne-osoby',
              '/kontakt/kontaktne-osoby',
            )}
            component={ContactPersonsPage}
          />
          <IndexRoute component={ContactInfoPage} />
        </Route>

        <Route path={'/activation/:code'} component={renderActivation} />

        <Route
          path={'/rychly-nakup'}
          component={() => (
            <Scan
              // fps={10}
              qrbox={{ width: 260, height: 450 }}
              disableFlip={false}
            />
          )}
        />
        <Route path="**" component={UrlMapper} />
        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
